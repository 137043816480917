.ins-header{
    --background: #0020AF;
    display: flex;
    height: 78px;
}
.ins-header ion-menu-button{
    --color: #FFF;
    font-size: 44px;
}
.ins-header ion-title {
    flex-direction: row;
    position: relative;
    padding-inline: 10px !important;
}
.back-btn{
    --color: #FFF;
    font-size: 44px;
}
.header-title{
    color: white;
    font-size: 20px;
    font-weight: bold;
}
.header-title ion-title{
    height: 100%;
}
.head-icons{
    float: right;
    font-size: 33px;
    color: #FFF;
    margin-right: 10px;
}
.hide-el{
    display: none;
}
ion-back-button{
    color: #fff;
    font-weight: 700;
}
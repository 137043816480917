.logo img{
    width: 100%;
    height: 260px;
    object-fit: cover;
}
h2{
    color: #0020AF;
}
.lgnheading h2{
    margin-top: 0px !important;
    font-size: 26px;
}
.lgnheading p{
    margin-bottom: 20px;
}
.inputArea{
    margin: 5px 0;
    padding: 8px 0px;
    font-size: 17px;
}
.lgnBtn{
    margin-top: 30px;
    font-size: 22px;
    --background: #0020AF;
    border-radius: 8px;
    height: 55px;
}
.inputArea {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.219);
    margin: 19px 0;
    padding: 8px 12px;
    font-size: 17px;
}
.inputArea span {
    margin-bottom: 4px;
    margin-right: 5px;
    font-size: 25px;
}
.bg-container{
    background-color: #0020AF;
    margin-top: -4px;
}
.main-content {
    padding: 24px !important;
}
.auth-terms-check {
    display: flex;
    align-items: center;
}

.auth-terms-check span {
    margin-left: 10px;
}
a{
    color: #0020AF!important;
    text-decoration: none;
}
.lgnheading {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}
.clr-blue{
    color: #0020AF!important;
}
.color-success{
    color: #5cb85c !important;
}
.color-danger{
    color: #dc3545 !important
}
.color-warning{
    color: #ffc107 !important
}
.reduced-select-width{
    width: 130px !important;
}
.cam-icon {
    font-size: 25px !important;
}
.d-none {
    display:none !important;
}
.custom-select {
    width: 70%;
    margin-left: 10px;
    border: none;
    height: 50px;
    font-size: 14px;
}
.startInspectionBtn{
    font-size: 18px;
    --background: #0020AF;
    border-radius: 8px;
    height: 45px;
}
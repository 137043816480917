.exterior-cards{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-inline: 0px;
    margin-bottom: 12px;
}
.exterior-cardtitle h5{
    font-size: 17px;
    margin-top: 0;
    font-weight: 600;
    margin-top: 3px;
}
.exterior-cardheader{
    display: flex;
    justify-content: space-between;
}
.exterior-cardheader span{
    margin-top: 0;
    margin-left: 10px;
    color: #0020AF;
    margin-top: -3px;
}
.star-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px auto;
    font-size: 25px;
}
.exterior-cardtitle ion-icon{
    font-size: 23px;
}
.ext-cameras{
    font-size: 26px !important;
}
.camera-panel{
    font-size: 26px !important;
}
.camera-other{
    font-size: 30px !important;
}
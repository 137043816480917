.notification-icon{
    display: flex !important;
    color: rgb(243, 0, 0);
    font-size: 86px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}
.notification-txt{
    font-size: 18px;
}

.docs-rc{
    width: 100%;
}
.docs-rc ion-card-title{
    font-weight: 600;
    font-size: 18px;
} 
.docs-rc ion-card{
    margin-inline: 0;
}
.doc-cards{
    display: flex;
}
.doc-cards ion-card-title{
    font-weight: 600;
    font-size: 18px;
} 
.doc-cards ion-card{
    width: 100%;
    margin-inline: 0px;
}
.docs-rc ion-card ion-list{
    padding-top: 0;
    padding-bottom: 0;
}
.doc-cards ion-card ion-list{
    padding-top: 0;
    padding-bottom: 0;
}
ion-label{
    margin-left: 8px;
}
.docsrcheader ion-card-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 18px;
}
.docsrcheader span{
    font-size: 29px;
    padding-left: 12px;
    color: #0020AF;
    margin-top: -7px;
}
.docsrctitle h4{
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 18px;
}
.chasis ion-card{
    width: 100%;
}
.docs-rcInsurance ion-card{
    width: 100%;
    margin-inline: 0;
}
.chs_cam{
    font-size: 80px;
}
.dropdown-container {
  width: 310px;
  margin: 10px;
  margin-bottom: 0;
  position: absolute;
  z-index: 100;
}
.doc-cards-select{
    /* display: flex; */
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
    height: 13vh;
    background-color: #fff;
    border-radius: 4px;
    border-width: 2px;
    box-shadow: 0px 3px 3px 1px rgb(0, 0, 0, 0.1);
}
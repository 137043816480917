.tyre-conditions{
    display: flex;
}
.tyre-conditions ion-card-title{
    font-weight: 600;
    font-size: 18px;
} 
.tyre-conditions ion-card{
    width: 100%;
    margin-inline: 0px;
}
.tyre-conditions ion-list{
    padding-top: 0;
    padding-bottom: 0;
}
#id{
    display: flex;
    
}
h6 {
    color: #0020AF;
}
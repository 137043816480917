.Splash{
    /* background-color: rgb(43, 12, 183); */
    background-color: #0020AF;
    height: 100%;
}
.Splash{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Splash .bot-img{
    position: absolute;
    bottom: 0;
    right: 0;
}

.Splash .top-img{
    position: absolute;
    top: 0;
    left: 0;
}

body{
    overflow-y: hidden !important;
}
.profileImg{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.profileImg img{
    height: 80px;
    width: 120px;
}
ion-card {
    margin-bottom: 20px;
}
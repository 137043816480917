.ins-report{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.insreport-icon{
    font-size: 51px;
    color: #0020AF;
}
.ins-report ion-icon{
    font-size: 61px;
}
.ins-report h1{
    color: #0020AF;
    font-size: 26px;
}
.ins-report p{
    margin-top: 0;
    text-align: center;
}
.ins-inputsearch{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #0020AF;
    padding: 20px 15px;
    margin: 15px 16px;
    border-radius: 6px;
    font-size: 18px;
}
.ins-inputsearch ion-icon{
    font-size: 40px;
}
.ins-inputsearch img{
    margin-right: 10px;
    height: 35px;
}
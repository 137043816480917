.card-modalsrch{
    margin:0 0 12px 0;
}
.card-modalsrch ion-card-title{
    font-size: 17px;
    font-weight: 600;
}
.modal-srch{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.modals-list{
    padding-top: 0;
    padding-bottom: 0;
}   
.carsmfg-year{
    display: flex;
    justify-content: space-between;
}
.mfgyear-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 14px;
}
.carsmfg-year ion-input{
    font-size: 16px;
    padding-inline: 6px !important;
}
.innerTextStyle{
    color: #111;
}
.reg-date input{
    padding: 3px 12px;
    border: none;
    outline: none;
    margin: auto;
}
.veh-color{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.veh-color ion-input{
    width: 0;
}
.modal-txtarea{
    margin-top: 20px;
    border: 1px solid rgba(0, 0, 0, 0.144);
}
.doc-cards{
    display: flex;
}
.dropdown-icon{
    margin-right: 10px;
}
.cam-iconss{
    display: flex;
    justify-content: space-between;
}
.cam-iconss ion-card-title{
    padding-top: 5px;
}
.cameras{
    font-size: 24px;
    margin-left: 10px;
    color: #0020AF;
    margin-top: 0px;
}
.ins-car-list{
    border: 2px solid;
}

.ins-car-list{
    border: 2px solid #0020AF;
}
.ins-car-list ion-card-header{
    padding: 10px;
}
ion-card{
    margin: 0px, 0px;
}
ion-searchbar {        
    transform: scale(1.1);
    margin: 0 auto;
}
.ins-details ion-icon{
        font-size: 32px;
        margin-bottom: 0px;
        margin-top: 9px;
}
.ins-details .car-icon{
    display: flex;
    justify-content: space-between;
    color: #0020AF;
    font-size: 38px;
}
.car-search .searchbar-input-container{
    height: 50px;
}
.registration-btn ion-button{
    --background: #0020AF;
    height: 67px;
    text-transform: none !important;
    font-size: 29px;
}
.registration-btn h4{
    color: #fff;
    font-size: 21px;
    margin: auto 6px;
}
.registration-btn ion-icon{
    margin-right: 9px;
    font-size: 38px;
}
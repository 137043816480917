
.cars-infosearch{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dddfff;
    padding: 2px 0px;
    margin: 0px 0px;
    border-radius: 6px;
    font-size: 18px;
}
.cars-infosearch ion-icon{
    font-size: 30px;
    margin-right: 7px;
    color: rgba(17, 16, 16, 0.39);
}
.cars-infosearch .carinfo-tgl{
    font-size: 30px;
    color: rgba(17, 16, 16, 0.39);
}
.cars-infosearch .carinfo-tglradio{
    font-size: 26px;   
}
.cars-infosearch span{
    margin-right: 8px;
    color: #2dd36f;
}
.info-details{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.info-details h3{
    color: #000;
    font-size: 17px;
    font-weight: 600;
}
.info-icondetails{
    font-size: 30px;
}
.info-cards{
    margin-inline: 0px !important;
    margin: 15px 5px !important;
    padding: 0px 0;
}
.airbags-cards{
    margin-top: 0;
    display: flex;
    justify-content: space-between;
}
.airbgcrd{
    margin-inline: 0px ;
}
/* .airbag-innercards ion-select{
} */
.airbag-innercards{
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.Elec-txtarea{
    width: 100%;
}
.airbag-innercards ion-card-title{
    font-weight: 600;
    font-size: 17px;
}
.airbags-cards ion-card-title{
    font-weight: 600;
    font-size: 17px;

}
.airbgcrd ion-card-title{
    font-weight: 600;
    font-size: 17px;

} 
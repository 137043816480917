.side-bar{
    --background: #0020AF !important;
}
.side-bar-icons{
    font-size: 32px;
    margin-right: 22px;
    color: #fff;
}
.side-bar ion-label{
    font-size: 19px !important;
}
ion-content .sidebar-list{
    --background: #0020AF;
    color: #fff;
}
.ins-sidetopbar{
    --background: #0020AF;
    border-bottom: 1px solid #fff;
}
.ins-sidetopbar h1{
    color: #fff;
}
.ins-sidetopbar p{
    color: #fff;
    margin: 6px 0 10px 0 !important;
    font-size: 16px;
}
.sidebar-menu{
    margin-left: -16px;
    background: none;
}